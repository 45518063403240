import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import BasicLayoutProps, {PageContainer, ProLayout, ProSettings, SettingDrawer} from "@ant-design/pro-layout"
import {useIntl} from "react-intl";
// eslint-disable-next-line import/named
import {Link, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import "./index.css";
import sideMenu from "../config/layoutMenuSettings";
import {IAppComponentProps} from "../components";
//import footer from "../components/footer/footer";
import RightContent from "../components/GlobalHeader/RightContent";
import {
    GithubFilled,
    InfoCircleFilled,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    QuestionCircleFilled, QuestionCircleOutlined, RightOutlined, UserOutlined
} from "@ant-design/icons";
import Welcome from "../pages/Welcome";
import DocumentsOverview from "../pages/documents/DocumentsOverview";
import EcapWelcome from "../pages/EcapWelcome";
import Users from "../pages/users/Users";
import MyDocuments from "../pages/documents/myDocuments";
import Assign from "../pages/users/Assign";
import check from "../components/Authorized/CheckPermissions";
import UserProfile from "../pages/users/UserProfile";
import Dashboard from "../pages/dashboard/Dashboard";
import SurveyLanding from "../pages/survey/SurveyLandingPage";
import SurveyOverview from "../pages/survey/SurveyOverview";
import SurveyManagement from "../pages/survey/SurveyManagement";
import SurveyMainWizard from "../pages/survey/SurveyMainWizard";
import Notifications from "../pages/notifications/Notifications";
import {entitlementExists} from "../main/entitlementService";
import CaptureDocument from "../pages/e-cap/capture-document";
import DraftDocuments from "../pages/e-cap/draft-documents/DraftDocuments";
import {getI18n, renderPeek} from "../utils/Utils";
import HelpFaqUserGuides from "../pages/help-faq/help-faq-user-guides";
import AccountPayableReports from "../pages/info-portal/components/AccountPayableReports";
import TemplateDocumentList from "../pages/e-cap/templates/templateList/TemplateLists-component";
import ManageTemplates from "../pages/e-cap/templates/captureTemplates/capture-template-component";
import InfoLogMain from "../pages/e-cap/reprocess-info-log/InfoLogMain";
import InternalReports from "../pages/info-portal/components/InternalReports";
import FsmDocumentOverview from "../pages/documents/FsmDocumentsOverview";
import NotificationsTemplate from "../pages/notifications/notifications-template/NotificationsTemplate"
import AddNotification from "../pages/notifications/create-notification/AddNotification";
import BannerManagement from "../pages/notifications/banner/BannerManagement";
import {from} from "@apollo/client";
import {ProCard, ProConfigProvider} from "@ant-design/pro-components";
import {Avatar, Button, ConfigProvider, Divider, Dropdown, Tooltip, Badge, Card} from "antd";
import styles from "../components/GlobalHeader/index.less";
import NoticeIconView from "../components/GlobalHeader/NoticeIconView";
import AvatarDropdown from "../components/GlobalHeader/AvatarDropdown";
import POFlipDisplay from "../pages/e-cap/po_flip/POFlipSearch";
import TechMissingInvoices from "../pages/dashboard/missing_invoices/TechMissingInvoices";
import ReportingConfiguration from "../pages/info-portal/components/admin/ReportingConfiguration";


const MainLayout: FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const currentUser = props.currentUser;
    const distinctEntitlements = props.distinctEntitlements;
    const entitlementPartner = props.entitlementPartner;
    const [pathname, setPathname] = useState("/welcome");
    const [collapsed, setCollapsed] = useState(false);

    const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: false,
    })


   // const isProduction = process.env.NODE_ENV !== 'development';
    const isProduction = process.env.REACT_APP_EINV_PORTAL_ENV !== 'INT';

    const actionRef = useRef<{
        reload: () => void;
    }>();

    const handleFAQClicked = () => {
        navigate("/help-faq-user-guides");
    };

    /**
     * filter menuData
     */
    const getSubMenu = item => {
        // doc: add hideChildrenInMenu
        if (item.children && !item.hideChildrenInMenu && item.children.some(child => child.name)) {
            return {
                ...item,
                children: filterMenuData(item.children) // eslint-disable-line
            };
        }
        return item;
    };

    const filterMenuData = menuData => {
        if (!menuData) {
            return [];
        }
        return menuData
            .filter(item => item.name && !item.hideInMenu)
            .map(item => check(item.authority, getSubMenu(item), undefined))
            .filter(item => item);
    };


    function copy(o) {
        return Object.assign({}, o)
    }

    //filters sidemenu based on entitlements - only entitled menu items will remain.
    const filterSideMenuOnEntitlements = () => {
        const resultRoutes = sideMenu(intl).route.routes.map(copy).filter(function f(o) {

            if (o.routes) {//if menu has routes, filter them using this same filter
                o.routes = o.routes.map(copy).filter(f);
                //here we can check if o.routes has any items, and if empty return false - this will remove top level menu's if no children exist
                if (o.routes.length === 0) {
                    return false;
                }
            }

            if (o.entitlement === undefined) {//if no entitlement filter is set - just return true
                return true;
            }

            if (entitlementExists(o.entitlement, props.distinctEntitlements)) {//if entitlement is set - check it against the distinct system entitlements.
                return true;
            }

        })
        const filteredResult: any = {}
        filteredResult.location = sideMenu(intl).location;
        filteredResult.route = {};
        filteredResult.route.routes = resultRoutes;
        return filteredResult;
    }


    const layoutProps = {
        ...filterSideMenuOnEntitlements(),
        location: {
            pathname
        },
        navTheme: "light",
        collapsed,
        collapsedButtonRender: false,
        menuItemRender: (item, dom) => (

            <a
                onClick={() => {
                    setPathname(item.path);
                }}
            >
                {dom}
            </a>

        )
    };

    const setPath = (name) => {
        setPathname(name);
    };


    useEffect(() => {
        let username = localStorage.getItem("username");
        if (!username || username === 'undefined') {
            username = props.currentUser?.uid;
            if (username && username !== 'undefined') {
                localStorage.setItem("username", username);
            } else {
                const token = localStorage.getItem("id_token");
                if (token) {
                    try {
                        const data = JSON.parse(atob(token.split('.')[1]));
                        username = data['sub'];
                        if (username) {
                            localStorage.setItem("username", username);
                        }
                    } catch (e) {
                        console.error('Oops', e);
                    }
                }
            }
        }

    }, []);

    if (typeof document === 'undefined') {
        return <div/>;
    }

    return (
        <>

            <div
                id="edp-pro-layout"
                style={{
                    height: '100vh',
                    overflow: 'scroll',
                }}
            >
                <ProConfigProvider hashed={false}>
                    <ConfigProvider
                        getTargetContainer={() => {
                            return document.getElementById('edp-pro-layout') || document.body;
                        }}
                    >
                        <ProLayout
                            key={"edp-pro-layout-key"}
                            prefixCls="my-prefix"
                            pageTitleRender={false}
                            {...filterSideMenuOnEntitlements()}
                            location={{
                                pathname,
                            }}
                            menu={{
                                collapsedShowGroupTitle: true,
                            }}

                            headerTitleRender={(_, __, ___) => {
                                const defaultDom = (
                                    <div className="logo img">
                                        <img className="main-logo" src="./../images/bmw-group-logo_white.png"
                                             alt="BMW Logo"/>
                                    </div>
                                );
                                if (typeof window === 'undefined') return defaultDom;
                                if (document.body.clientWidth < 1400) {
                                    return defaultDom;
                                }
                                if (___.isMobile) return defaultDom;
                                return (
                                    <>
                                        {defaultDom}
                                    </>
                                );
                            }}

                            actionsRender={(props) => {
                                return [
                                    <div key={"environment-ribbon"}
                                         style={{display: "flex", paddingRight: "20px"}}>
                                        <Button hidden={isProduction} danger={true} type="dashed">Integration</Button>
                                    </div>,
                                    <div key={"rightcontents-faq-tooltip-key"}
                                         style={{display: "flex", paddingRight: "20px"}}>
                                        <Tooltip title={intl.formatMessage({
                                            id: "rightcontents-faq-tooltip",
                                            defaultMessage: "Frequently Asked Questions"
                                        })}
                                        >
                                            <a
                                                style={{
                                                    color: "inherit"
                                                }}
                                                className={styles.action}
                                            >
                                                <QuestionCircleOutlined onClick={handleFAQClicked}/>
                                            </a>
                                        </Tooltip>
                                    </div>,
                                    <div key={"rightcontents-notifications-tooltip-key"}
                                         style={{display: "flex", paddingRight: "20px"}}>
                                        <Tooltip title={intl.formatMessage({
                                            id: "rightcontents-notifications-tooltip",
                                            defaultMessage: "Notifications"
                                        })}>
                                            <NoticeIconView currentUser={currentUser} intl={intl}
                                                            distinctEntitlements={distinctEntitlements}/>
                                        </Tooltip>
                                    </div>,
                                    <div key={"avatar-drop-down-key"}
                                         style={{display: "unset", paddingRight: "20px", alignContent: "right"}}>
                                        <AvatarDropdown currentUser={currentUser} menu={true} intl={intl}
                                                        distinctEntitlements={distinctEntitlements}
                                                        entitlementPartner={entitlementPartner}/>

                                    </div>

                                ];
                            }}


                            postMenuData={(menuData) => {
                                return [
                                    {
                                        name: " ",
                                        onTitleClick: () => setCollapsed(!collapsed)
                                    },
                                    ...(menuData || [])
                                ];
                            }}
                            menuItemRender={(menuItemProps, defaultDom) => {
                                if (menuItemProps) {
                                    if (menuItemProps.isUrl || !menuItemProps.path || location.pathname === menuItemProps.path) {
                                        return defaultDom;
                                    }
                                    return (<Link to={menuItemProps.path}
                                                  onClick={() => setPath(menuItemProps.path)}>
                                                <span
                                                    className={`${menuItemProps.classNames || ''} menu-item-to-uppercase`}>{
                                                    getI18n(menuItemProps.phraseId, menuItemProps.name, intl)}
                                                </span>
                                    </Link>);
                                }
                                return null;
                            }}

                            {...settings}
                        >


                            <Routes>
                                <Route key={"home"} path="/" element={<Welcome intl={intl} currentUser={currentUser}
                                                                               distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"welcome"} path="/welcome"
                                       element={<Welcome intl={intl} currentUser={currentUser}
                                                         distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"EcapWelcome"} path="/e-cap-welcome"
                                       element={<EcapWelcome intl={intl} currentUser={currentUser}
                                                             distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"users"} path="/users" element={<Users intl={intl} currentUser={currentUser}
                                                                                   distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"documents"} path="/documents/transportation"
                                       element={<MyDocuments intl={intl} currentUser={currentUser}
                                                             distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"documentsOverview"} path="/documents/documents-overview"
                                       element={<DocumentsOverview intl={intl} currentUser={currentUser}
                                                                   distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"infoPortal"} path="/reports/account-payable-reports"
                                       element={<AccountPayableReports intl={intl} currentUser={currentUser}
                                                                       distinctEntitlements={distinctEntitlements}/>}/>

                                <Route key={"infoPortalAdmin"} path="/reports/admin"
                                       element={<ReportingConfiguration intl={intl} currentUser={currentUser}
                                                                       distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"internalReports"} path="/reports/internal-reports"
                                       element={<InternalReports intl={intl} currentUser={currentUser}
                                                                 distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"fsmDocumentOverview"} path="/reports/fsm-report"
                                       element={<FsmDocumentOverview intl={intl} currentUser={currentUser}
                                                                     distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"assign"} path="/assign"
                                       element={<Assign intl={intl} currentUser={currentUser}
                                                        distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"profile"} path="/profile"
                                       element={<UserProfile intl={intl} currentUser={currentUser}
                                                             distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"infoLog"} path="/documents/info-log"
                                       element={<InfoLogMain intl={intl} currentUser={currentUser}
                                                             distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"dashboard"} path="/documents/dashboard"
                                       element={<Dashboard intl={intl} currentUser={currentUser}
                                                           distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"surveyLanding"} path={"/survey/survey-landing"}
                                       element={<SurveyLanding intl={intl} currentUser={currentUser}
                                                               distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"surveyOverview"} path={"/survey/survey-overview"}
                                       element={<SurveyOverview intl={intl} currentUser={currentUser}
                                                                distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"surveyManagementOverview"} path={"/survey/survey-management"}
                                       element={<SurveyManagement intl={intl} currentUser={currentUser}
                                                                  distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"surveyMasterData"} path={"/survey/survey-master-data"}
                                       element={<SurveyMainWizard intl={intl} currentUser={currentUser}
                                                                  distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"notificationsTemplate"} path={"/documents/notifications-template"}
                                       element={<NotificationsTemplate intl={intl} currentUser={currentUser}
                                                                       distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"surveyNotification"} path={"/notifications/notifications"}
                                       element={<Notifications intl={intl} currentUser={currentUser}
                                                                       distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"addNotification"} path={"/documents/add-notification"}
                                       element={<AddNotification intl={intl} currentUser={currentUser}
                                                                 distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"banner-management"} path={"/documents/banner-management"}
                                       element={<BannerManagement intl={intl} currentUser={currentUser}
                                                                  distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"missing-invoices"} path={"/documents/missing-invoices"}
                                       element={<TechMissingInvoices intl={intl} currentUser={currentUser}
                                                                  distinctEntitlements={distinctEntitlements}/>}/>                                                                  
                                <Route key={"documentProcessing"} path={"/ecap/document-processing"}
                                       element={<CaptureDocument intl={intl} currentUser={currentUser}
                                                                 distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"documentProcessing"} path={"/e-cap/po-flip"}
                                       element={<POFlipDisplay intl={intl} currentUser={currentUser}
                                                               distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"manageTemplates"} path={"/ecap/manage-templates"}
                                       element={<TemplateDocumentList intl={intl} currentUser={currentUser}
                                                                      distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"templateProcessing"} path={"/ecap/template-processing"}
                                       element={<ManageTemplates intl={intl} currentUser={currentUser}
                                                                 distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"e-cap"} path={"/e-cap/draft-documents"}
                                       element={<DraftDocuments intl={intl} currentUser={currentUser}
                                                                distinctEntitlements={distinctEntitlements}/>}/>
                                <Route key={"e-cap-help-faq"} path={"/help-faq-user-guides"}
                                       element={<HelpFaqUserGuides intl={intl} currentUser={currentUser}
                                                         distinctEntitlements={distinctEntitlements}/>}/>
                            </Routes>
                            <Outlet/>
                        </ProLayout>
                    </ConfigProvider>
                </ProConfigProvider>
            </div>

        </>
    );
};

export default MainLayout;



