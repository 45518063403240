import client from "../../../config/GraphQLApolloClient";
import {LIST_ALL_CONFIG, UPDATE_SAP_CONFIGURATION} from "../graphql/queries";
import {SapAdminConfig_SapSystemUpdate, SapAdminConfigVariables} from "../graphql/__generated__/SapAdminConfig";

export function updateSapAdminConfig(updateConfig:SapAdminConfig_SapSystemUpdate) {
    return client.query<SapAdminConfig_SapSystemUpdate,SapAdminConfigVariables>(
        {
            query: UPDATE_SAP_CONFIGURATION,
            variables: {sapConfig: updateConfig},
            fetchPolicy:'network-only'
        }).then(response => response.data);
};
export function getAllSapAdminConfig() {
    const result = client.query({
        query: LIST_ALL_CONFIG,
        fetchPolicy: "network-only"
    }).then(response =>  {
        const data = response.data.getSapSystemConfig;
        const updatableConfigList:SapAdminConfig_SapSystemUpdate[] = [];

        data.forEach(datum=> {
            const newItem: SapAdminConfig_SapSystemUpdate = {
                reportName: datum.reportName,
                description: datum.description,
                systemStatusList: datum.systemStatusList.map(systemItem => {
                        return {
                            id: systemItem.id,
                            path: systemItem.path,
                            url: systemItem.url,
                            active: systemItem.active
                        }
                    }
                )
            };

            updatableConfigList.push(newItem);
        });

        return updatableConfigList;
    });
    return result;
};
