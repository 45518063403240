import {getFilteredRulesWithRuleType} from "../__generated__/getFilteredRulesWithRuleType";
import {IStoredDocumentRecord} from "../IStoredDocumentRecord";
import {getControlRuleset} from "../RulesEngine";
import {FormInstance} from "antd";
import {readNestedFormValue} from "../../../utils/Utils";
import {IntlShape} from "react-intl/src/types";
import {PAGE_SIZE} from "../line-items/LineItems";

export declare type RuleSet = {
    required?: boolean;
    hidden?: boolean;
    readOnly?: boolean;
    picture?: string|string[];
    defaultValue?: any,
    formatMessage?:string|string[],
    infoMessage?:string;
};

export interface RuleDefinitions {
    rules: getFilteredRulesWithRuleType;
    rulesIndex: [];
    ruleMap: any;
}

export class RuleResultCache {
    private cache:any = {};
    private form: FormInstance<any>;
    private document: IStoredDocumentRecord;
    private ruleDefinitions: RuleDefinitions;
    private index: number;
    private debugFetch:boolean = false;
    private templateMode: boolean = false;

    constructor(
        ruleDefinition:RuleDefinitions,
        form:FormInstance<any>,
        document:IStoredDocumentRecord,
        ruleIDs:string[],
        intl?:IntlShape,
        index?:number,
        templateMode?:boolean
        ) {
        this.cache = {};
        this.form = form;
        this.document = document;
        this.ruleDefinitions = ruleDefinition;
        this.index = index;
        this.templateMode = templateMode;

        const DEFAULT_RESULT = {required: false, picture: '', readOnly: false, hidden: false, formatMessage:null, infoMessage:null};

        const mergeResult = (firstResult:RuleSet, secondResult:RuleSet)=> {
            const newResult: RuleSet = {...DEFAULT_RESULT};
            if (firstResult.hidden === false || secondResult.hidden === false) {
                newResult.hidden = false;
            }
            if (firstResult.readOnly===true || secondResult.readOnly === true) {
                newResult.readOnly = true;
            }
            if (firstResult.required===true || secondResult.required === true) {
                newResult.required = true;
            }

            if (firstResult.picture) {
                newResult.picture = firstResult.picture;
                if (firstResult.formatMessage) {
                    newResult.formatMessage = firstResult.formatMessage;
                }
                if (firstResult.infoMessage) {
                    newResult.infoMessage = firstResult.infoMessage;
                }
            }
            if (secondResult.picture) {
                newResult.picture = secondResult.picture;
                if (secondResult.formatMessage) {
                    newResult.formatMessage = secondResult.formatMessage;
                }
                if (secondResult.infoMessage) {
                    newResult.infoMessage = secondResult.infoMessage;
                }
            }

            return newResult;
        };

        const fieldController =
            (ruleID:string)=> {
                let resultFromRuleEngine = this.hardCodedRule(ruleID, form, document,intl,index);
                if (!resultFromRuleEngine) {
                    resultFromRuleEngine = getControlRuleset(ruleID, form, ruleDefinition, document,index);
                }
                return resultFromRuleEngine || DEFAULT_RESULT;
            };

        ruleIDs.forEach(ruleID => {
            const ruleResult = this.cache[ruleID];
            if (!ruleResult) {
                this.cache[ruleID] = fieldController(ruleID);
            }
            else {
                this.cache[ruleID] = mergeResult(ruleResult, fieldController(ruleID));
            }
        });
    }

    private verboseDebugging() {
        if (this.ruleDefinitions && this.ruleDefinitions.rulesIndex && this.ruleDefinitions.ruleMap && this.ruleDefinitions.rules) {
            const resultSet:any = {};
            const ruleFieldDependencies = {};
            this.ruleDefinitions?.rulesIndex.forEach(xpath => {
                resultSet[xpath] = {result:null,criteria:null};

                if (this.ruleDefinitions.ruleMap?.get(xpath)) {
                    const results = getControlRuleset(xpath, this.form, this.ruleDefinitions, this.document, this.index);
                    resultSet[xpath].result = results;
                    const rules =
                        this.ruleDefinitions.ruleMap?.get(xpath);

                    rules?.forEach(rule=> {
                       const definition =  JSON.parse(rule.definition);
                        const ruleArray = ruleFieldDependencies[definition.fieldName] || [];
                        if (ruleArray.indexOf(xpath)<0) {
                            ruleArray.push(xpath);
                        }
                        ruleFieldDependencies[definition.fieldName] = ruleArray;
                    });

                    resultSet[xpath].criteria = rules?.map(item=> `priority:${item.priority}, category: ${item.ruleCategory}, ${item.definition}`);
                }
            });

            console.log('Rule Result');
            console.log('form values',this.form.getFieldsValue());
            console.log('document',this.document);
            console.log('Results',resultSet);
            console.log('Field dependencies',ruleFieldDependencies);
            console.log('Rule Result');
        }
        else {
            console.log('Rules not loaded yet');
        }
    }

    private hiddenFetch(ruleID:string):RuleSet {
        return this.cache[ruleID] || {required: false, picture: '', readOnly: false, hidden: false,};
    }

    public enableSingleFetchDebug() {
        this.debugFetch = true;
        return this;
    }

    public fetchRule(ruleID:string, ithRuleID?:string,jthRuleID?:string,kthRuleID?:string,lthRuleID?:string):RuleSet {
        //performs an Or..., not an and...
        const result:RuleSet = {required: false, picture: '', readOnly: false, hidden: false,formatMessage:null, infoMessage:null};
        const ids = [ruleID,ithRuleID, jthRuleID, kthRuleID,lthRuleID];
        ids.forEach(id=> {
            if (id) {
                const ithFetch = this.hiddenFetch(id);
                result.required = result.required || (ithFetch.required!==undefined && ithFetch.required!==null && ithFetch.required);
                result.hidden = result.hidden || (ithFetch.hidden!==undefined && ithFetch.hidden!==null && ithFetch.hidden);
                result.readOnly = result.readOnly || (ithFetch.readOnly!==undefined && ithFetch.readOnly!==null && ithFetch.readOnly);
                if (ithFetch.picture) {
                    result.picture = ithFetch.picture;
                }
                if (ithFetch.formatMessage) {
                    result.formatMessage = ithFetch.formatMessage;
                }
                if (ithFetch.infoMessage) {
                    result.infoMessage = ithFetch.infoMessage;
                }
            }
        })

        if (this.debugFetch) {
            console.log('Fetch result for ',ids);
            console.log('Results',result);
            console.log('Document',this.document);
            console.log('Form',this.form.getFieldsValue());
            console.log('Rules',this.ruleDefinitions.ruleMap?.get(ruleID)?.map(item=>item.definition));
        }

        this.debugFetch = false;

        return result;
    }

    public formatMessage(ruleID:string, ithRuleID?:string,jthRuleID?:string,kthRuleID?:string,lthRuleID?:string) {
        return this.fetchRule(ruleID,ithRuleID, jthRuleID, kthRuleID,lthRuleID).formatMessage;
    }

    public formatInfoMessage(ruleID:string, ithRuleID?:string,jthRuleID?:string,kthRuleID?:string,lthRuleID?:string) {
        return this.fetchRule(ruleID,ithRuleID, jthRuleID, kthRuleID,lthRuleID).infoMessage || '';
    }

    public isHidden(ruleID:string, ithRuleID?:string,jthRuleID?:string,kthRuleID?:string,lthRuleID?:string) {
        return this.fetchRule(ruleID,ithRuleID, jthRuleID, kthRuleID,lthRuleID).hidden;
    }
    public isReadOnly(ruleID:string, ithRuleID?:string,jthRuleID?:string,kthRuleID?:string,lthRuleID?:string) {
        return this.fetchRule(ruleID,ithRuleID, jthRuleID, kthRuleID,lthRuleID).readOnly;
    }
    public isMandatory(ruleID:string, ithRuleID?:string,jthRuleID?:string,kthRuleID?:string,lthRuleID?:string) {
        return this.fetchRule(ruleID,ithRuleID, jthRuleID, kthRuleID,lthRuleID).required;
    }
    
    public debugRender(renderer:(ruleCache:RuleResultCache)=>any) {
        this.verboseDebugging();
        return this.render(renderer);
    }

    public render(renderer:(ruleCache:RuleResultCache)=>any) {
        return renderer(this);
    }

    private hardCodedRule(xpath,  form: FormInstance<any>, document: IStoredDocumentRecord, intl?:IntlShape,index?:number):RuleSet {
        const DEFAULT_RESULT:RuleSet = {required: false, picture: '', readOnly: false, hidden: false, formatMessage:null, infoMessage:null};
        function isOrderNumberDisabled() {
            return form.getFieldValue("orderNumberNA") === 'ORDER_NUM_DISABLED';
        }

        if (xpath ==='NA') {
            return DEFAULT_RESULT
        }

        if (xpath === 'Always_Mandatory') {
            return {required: true, picture: '', readOnly: false, hidden: false,};
        }

        if (xpath === 'Template_Non_Mandatory_Else_Mandatory') {
            if (this.templateMode)
            {
                return {required: false, picture: '', readOnly: true, hidden: false,};
            }
            return {required: true, picture: '', readOnly: false, hidden: false,};
            
        }

        if (xpath === 'Original_Invoice') {
            const result = DEFAULT_RESULT;

            result.readOnly = true;

            // eslint-disable-next-line eqeqeq
            if (document.documentType == 2) {
                result.required = true;
                result.readOnly = false;
            }

            if (!readNestedFormValue(form,'document.originalInvoiceNumber') && readNestedFormValue(form,'document.agreementReference')) {
                result.readOnly = true;
                result.required =false;
            }

            return result;
        }

        if (xpath === 'Agreement_Reference') {
            const result = DEFAULT_RESULT;

            result.readOnly = true;

            // eslint-disable-next-line eqeqeq
            if (document.documentType == 2) {
                result.required = true;
                result.readOnly = false;
            }

            if (readNestedFormValue(form,'document.originalInvoiceNumber') && !readNestedFormValue(form,'document.agreementReference')) {
                result.readOnly = true;
                result.required = false;
            }

            return result;
        }

        if (xpath === 'Footer_Text') {
            const result = DEFAULT_RESULT;
            result.picture =  '^.{0,450}$';
            result.formatMessage = intl.formatMessage({id:'field-maximum-warning'}).replace('__number__','450');

            return result;
        }

        if (xpath === 'Exemption_Reason') {
            const result = DEFAULT_RESULT;

            const taxRate = (document?.lineItems?.length > index ? document.lineItems[index]?.taxRate: form.getFieldValue(`line_item_taxRate_${index % PAGE_SIZE}`)) || 0;

            result.picture =  '^.{0,255}$';

            if (taxRate > 0) {
                result.readOnly = true;
            }
            else {
                result.required = true;
                result.picture =  '^.{1,255}$';
            }


            result.formatMessage = intl.formatMessage({id:'field-maximum-warning'}).replace('__number__','255');


            return result;
        }

        if (xpath === 'Exemption_Type') {
            const result = DEFAULT_RESULT;

            const taxRate = (document?.lineItems?.length > index ? document.lineItems[index]?.taxRate: form.getFieldValue(`line_item_taxRate_${index % PAGE_SIZE}`)) || 0;
            let exemptionCount = 0;

            if (document.lineItems[index]?.taxLawReferenceId) {
                exemptionCount = [3,11].indexOf(document.lineItems[index]?.taxLawReferenceId)>-1 ? 1 : 0;
            }
            else {
                const typeValues = `exemption_type_number_${index % PAGE_SIZE}`;
                exemptionCount = form.getFieldValue(typeValues) || 0;
            }

            if (taxRate > 0) {
                result.readOnly = true;
            }
            else if (exemptionCount>0) {
                result.required = true;
            }
            else {
                result.readOnly = true;
            }

            return result;
        }

        if (xpath === 'Order_Number_Format') {
                const result = DEFAULT_RESULT;

                if ('A1,MU,B7,UH,RK,LG,U0,FE'.split(',').indexOf(document.companyCode)>-1) {
                    result.picture = '^([4][0-9]{9})$|^([a-zA-Z012356789][a-zA-Z0-9]{6})$';
                    result.formatMessage = intl.formatMessage({id:'ecap-order-num-validation-message-1',defaultMessage:'Invalid  format: Order number must be 7 alphanumeric characters" OR "Order number must 10 numeric digits starting with a 4"'});
                    result.infoMessage = intl.formatMessage({id:'ecap-order-num-info-message-1',defaultMessage:'Order number can contain a 7 alphanumeric code e.g. ABC123 or Order number can be only 10 numeric characters starting with a 4… Please refer to the BMW Document for the correct order number, a missing or incorrect order number can lead to the rejection of he invoice'});
                }
                else if ('H9,T1,T2,DQ'.split(',').indexOf(document.companyCode)>-1) {
                    result.picture = '^(([0-9]{1,10}))$';
                    result.formatMessage = intl.formatMessage({id:'ecap-order-num-validation-message-2',defaultMessage:'Invalid  format: Order number can be a maximum  of 10 numeric digits'});
                    result.infoMessage = intl.formatMessage({id:'ecap-order-num-info-message-2',defaultMessage:'Order number can be a maximum of 10 numeric digits. Please refer to the BMW Document for the correct order number, a missing or incorrect order number can lead to the rejection of he invoice'});
                }
                else if ('C5,S5'.split(',').indexOf(document.companyCode)>-1) {
                    result.picture = '^(4[0-9]{9})$|^([a-zA-Z012356789][a-zA-Z0-9]{6})$';
                    result.formatMessage = intl.formatMessage({id:'ecap-order-num-validation-message-3',defaultMessage:'Invalid  format: Order number should be 7 digits alphanumeric e.g. F37Q3F1' +
                        'Or order number can be 10 digits numeric starting with a 4…  e.g. 4500335554' +
                        'Or 7 digits numeric e.g. 3681836'});
                    result.infoMessage = intl.formatMessage({id:'ecap-order-num-info-message-3',defaultMessage:'Invalid  format: Order number should be 7 digits alphanumeric e.g. F37Q3F1 Or order number can be 10 digits numeric starting with a 4…  e.g. 4500335554 Or 7 digits numeric e.g. 3681836'});
                }
                else if ('LY' === document.companyCode) {
                    result.picture = '^([a-zA-Z0-9]{7})$|^([8][0-9]{9})$|^([7][0-9]{7})$';
                    result.formatMessage = intl.formatMessage({id:'ecap-order-num-validation-message-6',defaultMessage:"Invalid format: Order number must be 7 alphanumeric characters OR Order number must be 10 numeric digits starting with a \"8\" OR Order number must be 8 numeric digits starting with a \"7\""});
                    result.infoMessage = intl.formatMessage({id:'ecap-order-num-info-message-6',defaultMessage:'Order number can be only 7 digits alphanumeric e.g. F3LL6TJ or numeric e.g. 3784049. Order number can be only 10 digits numeric starting with a 8…  e.g. 8302137396. Order number can be only 8 digits numeric starting with a 7…  e.g. 70090505'});

                }
                else if ('B1' === document.companyCode) {
                    result.picture = '^(([0-9]{1,10})|([a-zA-Z0-9]{7}))$';
                    result.formatMessage = intl.formatMessage({id:'ecap-order-num-validation-message-5'});
                    result.infoMessage = intl.formatMessage({id:'ecap-order-num-info-message-5'});
                }
                else {
                    result.picture = '^(([0-9]{1,10}))$';
                    result.formatMessage = intl.formatMessage({id:'ecap-order-num-validation-message-4',defaultMessage:'Invalid  format: Order number can be a maximum  of 10 numeric digits'});
                    result.infoMessage = intl.formatMessage({id:'ecap-order-num-info-message-4',defaultMessage:'Order number can be a maximum of 10 numeric digits. Please refer to the BMW Document for the correct order number, a missing or incorrect order number can lead to the rejection of he invoice'});
                }

                return result;
        }

        if (xpath === 'Delivery_Service_Number_Format') {
            const result = DEFAULT_RESULT;

            result.picture = '^.{1,25}$';
            result.formatMessage = intl.formatMessage({id:'ecap-delivery-service-num-1',defaultMessage:'Delivery / Service number cannot exceed 25 characters'});

            return result;
        }

        if (xpath === 'Document_Number_Format') {
            const result = DEFAULT_RESULT;

           result.picture = [
               '^[a-zA-Z0-9,.+/#&\'\"\(\)\-\_äöü ]+$',
               '^.{1,16}$'
           ];

            result.formatMessage = [
                intl.formatMessage({id:'ecap-document-num-1',defaultMessage:'All characters must be a combination of letters (a-z, A-Z), numbers, or one of the following: & * : # _ - space'}),
                intl.formatMessage({id:'ecap-document-num-2',defaultMessage:'Document number cannot exceed 16 characters'}),
            ];

            return result;
        }

        if (xpath === 'Part_Number_Format') {
            const result = DEFAULT_RESULT;

            result.picture = '^(([0-9a-zA-Z]{7}[\\-]([0-9a-zA-Z]{2}))|([0-9a-zA-Z]{7})|([0-9a-zA-Z]{7}[\\.][0-9a-zA-Z]{4})|([0-9]{5}))$';
            result.formatMessage = intl.formatMessage({id:'ecap-bmw-part-num-1',defaultMessage:'The BMW Part Number should follow one of the below specific formats:\n' +
                    'xxxxxxx-xx alphanumeric or\n' +
                    'xxxxxxx alphanumeric or\n' +
                    'xxxxxxx.xxxx alphanumeric or\n' +
                    'xxxxx numeric'});

            result.infoMessage = intl.formatMessage({id:'ecap-bmw-part-num-info-message-1',defaultMessage:'The BMW Part Number should follow one of the below specific formats:\n' +
                    'xxxxxxx-xx alphanumeric or\n' +
                    'xxxxxxx alphanumeric or\n' +
                    'xxxxxxx.xxxx alphanumeric or\n' +
                    'xxxxx numeric'});

            if ('C5' === document.companyCode) {

                result.picture = '^(([0-9]{6}[\\.][0-9]{2})|([0-9a-zA-Z]{1,18}))$';
                result.formatMessage = intl.formatMessage({id: 'ecap-bmw-part-num-2', defaultMessage: 'Invalid  format:  The BMW Part Number should follow one of the below specific formats: xxxxxx.xx numeric OR alphanumeric (18 char max)'
                });
                result.infoMessage = intl.formatMessage({id: 'ecap-bmw-part-num-info-message-2', defaultMessage: 'The BMW Part Number should follow one of the below specific formats: xxxxxx.xx numeric OR alphanumeric (18 char max)'
                });
            }

            return result;
        }

        if (xpath === 'Item_Description') {
            const result = DEFAULT_RESULT;

            result.required = true;
            result.picture =  '^.{1,250}$';
            result.formatMessage = intl.formatMessage({id:'field-maximum-warning'}).replace('__number__','250');

            return result;
        }

        if (xpath === 'Early_Payment_Mandatory') {
            const result = DEFAULT_RESULT;

            // eslint-disable-next-line eqeqeq
            if (document.documentType == 1 || document.documentType == 2 || document.documentType == 3 || document.documentType == 15) {
                result.hidden = true;
            }
            else {
                result.required = true;
            }

            return result;
        }

        if ((xpath === 'Service_Date' || xpath === 'Delivery_Service_Number') && !this.templateMode) {
            const result = DEFAULT_RESULT;
            // @ts-ignore
            // eslint-disable-next-line eqeqeq
            if (document?.documentType == 1 || document?.documentType == 2 || document?.documentType == 3 || document?.documentType == 15) {
                result.required = true;
            }

            if (isOrderNumberDisabled()) {
                result.required = false;
            }

            if (document?.companyCode === 'LY') {
                result.readOnly = true;
                result.required = false;
            }

            if (xpath === 'Delivery_Service_Number') {
                result.infoMessage = intl.formatMessage({id:'ecap-delivery-service-number-field-info'});
            }

            if (xpath === 'Service_Date') {
                if (document?.documentType === 4) {
                    result.readOnly = true;
                    result.required = false;
                }
            }

            return result;
        }
        else if ((xpath === 'Service_Date' || xpath === 'Delivery_Service_Number') && this.templateMode)
        {
            const result = DEFAULT_RESULT;
            result.readOnly = true;
            result.required = false;

            if (xpath === 'Delivery_Service_Number') {
                result.infoMessage = intl.formatMessage({id:'ecap-delivery-service-number-field-info'});
            }

            return result;
        }

        if (xpath === 'Order_Number') {
            const result = DEFAULT_RESULT;

            // @ts-ignore
            // eslint-disable-next-line eqeqeq
            if (document?.documentType == 1 || document?.documentType == 2 || document?.documentType == 3 || document?.documentType == 15) {
                result.required = true;
            }

            if (isOrderNumberDisabled()) {
                result.readOnly = true;
                result.required = false;
            }

            if (['LY','LG','FE'].indexOf(document?.companyCode)>-1) {
                result.readOnly = true;
                result.required = false;
            }

            return result;

        }

        if (xpath === 'Order_Number_NA') {
            const result = DEFAULT_RESULT;

            if (['LY','LG','FE'].indexOf(document?.companyCode)>-1) {
                result.readOnly = true;
            }

            return result;
        }

        if (xpath === 'Line_Item_Tools_Check') {
            const result = DEFAULT_RESULT;
            if (document?.documentType === 3) {
                result.required = true;
            }

            return result;
        }

        if (xpath === 'Line_Item_Tools_Location') {
            const result = DEFAULT_RESULT;
            if (document?.documentType === 3) {
                result.required = true;
                result.picture =  '^.{1,255}$';
            }
            else {
                result.picture =  '^.{0,255}$'
            }


            result.formatMessage = intl.formatMessage({id:'field-maximum-warning'}).replace('__number__','255');

            return result;
        }

        if (xpath === 'Exchange_Rate') {
            const result = DEFAULT_RESULT;
            const localCurrency = form.getFieldValue(["document", "localCurrencyCodeId"]);

            if (localCurrency) {
                result.required = true;
            }

            return result;
        }

        return null;
    }
}

export function augmentWithMaximumLength(ruleSet:RuleSet, maxLength:number, intl:IntlShape) {
    if (ruleSet) {
        ruleSet.formatMessage = intl.formatMessage({id:'field-maximum-warning'}).replace('__number__',maxLength.toString());
        if (ruleSet.required) {
            ruleSet.picture = `^.{1,${maxLength}}$`;
        }
        else {
            ruleSet.picture = `^.{0,${maxLength}}$`;
        }
    }

    return ruleSet;
}
