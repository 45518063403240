import { LineItemsInput } from "../../../__generated__/globalTypes";
import { GET_invoice_agreement_history_V2_getAgreementHistoryV2 } from "./__generated__/GET_invoice_agreement_history_V2";
import { getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes } from "./__generated__/getPartnerVATInfoByPartnerNo";
import { AttachmentModel } from "./attachments/Attachment";
import { GET_invoice_agreement_history_getAgreementHistory } from "./supplier/__generated__/GET_invoice_agreement_history";

export enum MODE {
  EDIT = 1,
  VIEW,
  OVERVIEW,
}

// export interface IStoreDocumentOverviewFilters {
//   key: string;
//   filterFromReceptionDate: string,
//   filterToReceptionDate: string,
//   filterPartnerNumber: number,
//   documentTypeId: number,
//   filterFromDocumentDate: string,
//   filterToDocumentDate: string,
//   filterDocumentNumbers: number,
//   filterAttachment: boolean,
//   filterBMWCompanyCode: number,
//   filterProcessCode: number,
//   filterFormat: string,
//   filterVinNumber: number
// }

export interface IStoreLineItem {
  key: string;
  orderNumber: string;
  itemDescription: string;
  bmwPartNumber: string;
  deliveryOrServiceNumber: string;
  quantityUnit: string;
  quantity: number;
  unitPrice: number;
  unitPriceQuantity:number;
  taxRate: number;
  taxLawReference: string;
  exceptionType: string;
  exceptionReason: string;
  toolLocation: string;
  supplyingCountry: string;
  inventoryNumber: string;
  srmRefNumber: string;
}

export interface IStoredDocumentRecord {
  documentType: number
  documentMode: MODE.VIEW | MODE.EDIT | MODE.OVERVIEW;
  supplier: string
  bmwGroupPaymentRequest: boolean
  partnerId: number
  bmwIsRetailOutlet: boolean
  companyCode: string
  bmwBusinessUnitId: number
  bmwBusinessAreaId: number
  companyPartnerId: number
  lineItems: LineItemsInput[]
  documentId: number
  orderNumber: string
  collapsedState: string | string[]
  shipFromCountryCode: string;
  shipToCountryCode: string;
  shipFromRegionCode: string;
  shipToRegionCode: string;
  companyVatCodeId: number;
  supplierCountryCode: string;
  fiscalCountryCode: string;
  supplierRegionCode: string;
  fiscalRegionCode: string;
  partnerVatCodeId: number;
  legalFormTypeId: number;
  fiscalRepresentativeChecked: boolean;
  inLiquidationChecked: boolean;
  agreementHistory: GET_invoice_agreement_history_V2_getAgreementHistoryV2;
  attachments: AttachmentModel[];
  attachmentUploadsLeft: number;
  companyCountryCode: string;
  isTemplateMode: boolean;
  copyTemplate: boolean;
  templateCount: number;
  deliveryDateInError: boolean
  partnerNo: string
  bmwCompanyCity: string
  bmwCompanyCountryCode: string
  bmwCompanyPostalCode: string
  bmwCompanyStreet1: string
  bmwCompanyStreet2: string
  partnerVatCode: string
  partnerVatCountryCode: string
  companyVatCode: string
  companyVatCountryCode: string
  partnerName: string
  systemCode: string
  partnerAddressStreet1: string
  partnerAddressStreet2: string
  partnerAddressPostalCode: string
  partnerAddressRegionCode: string
  partnerAddressCity: string
  partnerAddressCountryCode: string
  partnerTaxId: string
  bmwCompanyTaxId: string
  companyName: string
  loading: number
  isOrderNumberNotRequired: boolean
  partnerVatSelectOptions: getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes[]
  // documentOverviewFilters: []
  // filterFromReceptionDate: undefined,
  // filterToReceptionDate: undefined,
  // filterPartnerNumber: number,
  // filterDocumentType: string,
  // filterFromDocumentDate: undefined,
  // filterToDocumentDate: undefined,
  // filterDocumentNumbers: undefined,
  // filterAttachment: boolean,
  // filterBMWCompanyCode: string,
  // filterProcessCode: string,
  // filterFormat: string,
  // filterVinNumber: string
}