import React, {useEffect, useMemo, useRef, useState} from 'react';
import './../Welcome.css';
import './../survey/SurveyOverview.css';
import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    ConfigProvider,
    Dropdown, Flex,
    Form,
    Menu,
    Modal,
    notification, Progress,
    Row,
    Space,
    Spin,
    Tabs,
    Tooltip,
    Typography,
} from 'antd';
import {PageContainer} from '@ant-design/pro-layout';
import {FailureDownloadDocsRespEcap, IAppComponentProps} from '../../components';
import styles from '../../styles/scss/portal.scss';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {GridApi} from 'ag-grid-community';
import enUS from 'antd/lib/locale/en_US';
import deDe from 'antd/lib/locale/de_DE';
import {MYLISTDOCS_TABLE_HEADERS} from './ListDocumentsUtils';
import {UPDATE_SINGLE_DOWNLOAD_STATUS} from './mutations';
import MyListDocumentsSearchForm from './myListDocumentsSearchForm';
//import { getDocumentsDataList } from "./DocumentListservice";
import {getDocumentsData} from './DocumentListservice';
import {useIntl} from 'react-intl';
import {getI18n, isExternalUser} from '../../utils/Utils';
import {useLazyQuery, useMutation} from '@apollo/client';
import {DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID} from '../../main/queries';
import {getAgGridLocalization, PersonalizeGridView} from '../info-portal/components/grid/GridUtil';
import FsmDocumentsOverview from './FsmDocumentsOverview';
import {
    getDocumentUserEntitlementResultByUserId,
} from '../../main/__generated__/getDocumentUserEntitlementResultByUserId';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useAppDispatch, useAppSelector} from "../../main/hooks";
import moduleStyles from './styling/document.module.css';
import {
    DownloadOutlined,
    DownOutlined,
    EyeOutlined,
    ReloadOutlined,
    SaveOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {downloadAndSaveZipDocuments, DownloadProgressProperties} from "./DocumentsUtil";
import Paragraph from "antd/es/typography/Paragraph";

dayjs.extend(utc);

const DocumentsOverview: React.FunctionComponent<IAppComponentProps> = (props) => {
    const [loading, isLoading] = useState(false);
    const downloadProgressProperties = useRef<DownloadProgressProperties>({cancelling: false, progress:100});

    const dispatch = useAppDispatch();
    const { documentOverviewFilters } = useAppSelector((state) => state.documentOverview);
    const [updateDownLoadStatus] = useMutation(UPDATE_SINGLE_DOWNLOAD_STATUS);
    const currentUser = props.currentUser;
    const [form] = Form.useForm();
    const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
    const [docData, setDocData] = useState(undefined);
    const [partnerNos, setPartnerNos] = useState<string[]>(undefined);
    const [docCount, setDocCount] = useState(0);
    const [formDataValues, setFormDataValues] = useState(undefined);
    const [failureDocuments, setFailureDocuments] = useState<FailureDownloadDocsRespEcap[]>(undefined);
    const [flag,setFlag] = useState<boolean>(false);
    const Overview_Entitlement = 'overview@documents';

    const { TabPane } = Tabs;

    const sizes: React.CSSProperties = {
        height: '100%',
        width: '98%',
    };

    const intl = useIntl();

    const gridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'document_overview_ag_grid_personalized_view',
            MYLISTDOCS_TABLE_HEADERS(props.intl, props.currentUser)));

    const [filteredEntitlementLookupForSelf, {}] = useLazyQuery<getDocumentUserEntitlementResultByUserId>(
        DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID,
        {
            variables: {
                userId: props.currentUser?.uid,
                entitlement: Overview_Entitlement,
                partnerName: String,
                authentication: localStorage.getItem('id_token'),
            },
            fetchPolicy: 'network-only',
        },
    );

    const excelStyles = useMemo(() => {
        return [
            {
                id: 'currencyFormat',
                numberFormat: {
                    format: '#,##0.00',
                },
            },
        ];
    }, []);

    const reload = ()=>setFlag(prevState=>!prevState);

    const onDocGridReady = (params) => {
        setDocGridApi(params.api);
        gridView.current.initPersonalizedView(params.api);
    };

    const onSearch = (values) => {
        isLoading(true);
        setDocCount(0);
        setDocData([]);
        setFormDataValues(values);

        const docListInput = {
            receptionDateFrom: values.receptionDateFrom
                ? dayjs(values.receptionDateFrom).format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD'),
            receptionDateTo: values.receptionDateTo
                ? dayjs(values.receptionDateTo).format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD'),
            documentDateFrom: values.documentDateFrom ? dayjs(values.documentDateFrom).format('YYYY-MM-DD') : undefined,
            documentDateTo: values.documentDateTo ? dayjs(values.documentDateTo).format('YYYY-MM-DD') : undefined,

            bmwCompanyId: values.bmwCompanyId ? parseInt(values.bmwCompanyId) : undefined,
            bmwCompanyIdList: values.bmwCompanyIdList?.length ? values.bmwCompanyIdList.map(item=>parseInt(item)) : undefined,
            documentNumbers: values.documentNumbers,
            documentTypeId: values.documentTypeId ? parseInt(values.documentTypeId) : undefined,
            downloaded: values.downloaded ? parseInt(values.downloaded) : undefined,
            attachment: values.attachment ? parseInt(values.attachment) : undefined,
            formatId: values.formatId ? parseInt(values.formatId) : undefined,
            partnerId: values.partnerId ? parseInt(values.partnerId) : undefined,
            partnerNo: values.partnerNo ? values.partnerNo : undefined,
            processCodeId: values.processCodeId ? parseInt(values.processCodeId) : undefined,
            statusId: values.statusId ? parseInt(values.statusId) : undefined,
            vinNumber: values.vinNumber,
            partnerNoList: values.partnerNoList ? values.partnerNoList : partnerNos
        };

        console.log(docListInput);

        localStorage.setItem('docListInput', JSON.stringify(docListInput));
        Promise.resolve(getDocumentsData(currentUser, docListInput, intl))
            .then((data) => {
                setDocCount(data.getPartnerDocsList.length);
                const docList = [];
                data?.getPartnerDocsList.map((item) => {
                    const document = { ...item };
                    document.downloaded =
                        item.downloaded === 'false'
                            ? getI18n('general-edp-no', 'NO', intl)
                            : getI18n('general-edp-yes', 'YES', intl);
                    document.downloadedBool = item.downloaded === 'false' ? false : true;
                    document.attachment =
                        item.attachment === 'false'
                            ? getI18n('general-edp-no', 'NO', intl)
                            : getI18n('general-edp-yes', 'YES', intl);
                    document.attachmentBool = item.attachment === 'false' ? false : true;
                    docList.push(document);
                });

                setDocCount(docList?.length);
                setDocData(docList);

                isLoading(false);
            })
            .catch((err) => {
                isLoading(false);
                console.error(err);
            });
    };

    const onExport = (_: any) => {
        const selectedRows = docGridApi.getSelectedRows();
        console.dir(selectedRows);

        if (selectedRows.length === 0) {
            //console.log("Error")
            Modal.warning({
                title: getI18n(
                    'Warning-message',
                    'Export Document(s)', intl,
                ),
                content: getI18n(
                    'Warning-export-message',
                    'You have not selected any items to be exported. Please select an item first.', intl,
                ),
            });
        } else {
            docGridApi.exportDataAsExcel({
                onlySelectedAllPages: false,
                onlySelected: true,
                fileName: 'Document_Overview_Export',
            });
        }
    };

    const onSave = (_: any) => {
        gridView.current.savePersonalizedView();
        reload();
        notification.info({ message: intl.formatMessage({ id: 'report-result-preferences-saved' }) });
    };

    const onApplyPerson = (_: any) => {
        gridView.current.applyPersonalizedView();
    };

    const onReset = (_: any) => {
        docGridApi.setFilterModel(null);
        gridView.current.reset();
    };

    const downIcon = () => <DownOutlined />;

    const menu = (
        <Menu>
            <Menu.Item key="3" disabled={!gridView.current.hasPersonalizedView()}>
                <UserOutlined onClick={onApplyPerson} />
                <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
                    {intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">
                <SaveOutlined onClick={onSave} />

                <a data-testid={'info-portal-save'} onClick={onSave}>
                    {intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
                </a>
            </Menu.Item>
        </Menu>
    );

    const cancelDownload = ()=> {
        downloadProgressProperties.current.cancelling = true;
        reload();
    };

    const downloadDocuments = (_: any) => {
        isLoading(true);
        const selectedRows = docGridApi.getSelectedRows();

        // loop through selected rows if attachment is "No", return a message that the document is not available for download
        const invalidAttachments = selectedRows.filter((row) => row.attachmentBool === false);
        if (invalidAttachments.length > 0) {
            isLoading(false);
            Modal.warning({
                title: getI18n('Download Warning', `Warning`, intl),
                content: getI18n('Invalid-Attachment-Warning-Message', `The selected document(s) cannot be downloaded, 
        as one or more of the selected document(s) does not have an attachment yet. 
        Please only select documents to download where the attachment(s) is available – marked as ‘Yes’ in the Attachment column. 
        If the attachment is not yet available to download, please try again later.`, intl),
            });
            return;
        }

        const documentDownloadInput = [];

        for (const documentEcap of selectedRows) {
            documentDownloadInput.push({
                id: documentEcap.blobId,
                messageId: documentEcap.messageId,
            });
        }

        downloadAndSaveZipDocuments(
            {
                    list:documentDownloadInput,
                    fileName:new Date().toISOString().substring(0, 10).concat('-documentOverview.zip'),
                    downloadProgressProperties:downloadProgressProperties.current,
                    batchSize:100,
                    progressNotifier:reload
                }
            )
            .then((response) => {
                isLoading(false);

                if (!downloadProgressProperties.current.cancelling) {
                    setFailureDocuments(response.data.downloadDocumentEcap.failureDocuments);
                    onSearch(documentOverviewFilters);
                    Modal.success({
                        title: getI18n(
                            'Download Success',
                            'Success', intl,
                        ),
                        content: getI18n(
                            'Download-Success-Message',
                            'Document(s) Downloaded successfully', intl,
                        ),
                        okText: 'OK',
                    });
                    onSearch(formDataValues);
                }
                downloadProgressProperties.current.cancelling = false;
            }).catch((error:Error) => {
                console.error(error);
                isLoading(false);
                downloadProgressProperties.current.cancelling = false;

                let errorMessage = getI18n(
                    'Empty-blobId-Warning-Message',
                    'Document still in progress. Please try again later. (See the Empty Folder Downloaded)', intl,
                );

                if (error.message === 'Timeout exceeded') {
                    errorMessage = getI18n(
                        'document-overview-timeout-message',
                        'Timeout occurred, please try again', intl,
                    );
                }

                Modal.warning({
                    title: getI18n(
                    'Download Warning',
                    'Warning', intl,
                ),
                content: errorMessage,
            });
        });
    };
    const gridRef = useRef();

    useEffect(() => {
        isLoading(true);
        const input = {
            receptionDateFrom: dayjs().startOf('d').format('YYYY-MM-DD'),
            receptionDateTo: dayjs().endOf('d').format('YYYY-MM-DD'),
        };


        if (isExternalUser(props.distinctEntitlements)) {
            isLoading(true);
            const partnerNos: string[] = [];
            filteredEntitlementLookupForSelf()
                .then((response) => {
                    return new Promise<string[]>((resolve) => {
                        const userEntitlement = response.data?.getDocumentUserEntitlementResultByUserId;
                        userEntitlement.partners.map((item) => {
                            partnerNos.push(item.partnerNo);
                        });
                        setPartnerNos(partnerNos);
                        resolve(partnerNos);
                    });
                })
                .then((partnerNoList) => {
                    onSearch({ ...input, partnerNoList });
                });
        } else {
            isLoading(false);
            setDocData(null);
        }
    }, [props.currentUser]);

    return (
        <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
            <PageContainer
                title={false}
                content={''}
                extraContent={''}
                className={styles.users}
                style={{ background: 'white' }}
            >
                <Modal open={loading && downloadProgressProperties.current.progress<100}
                       footer={[
                           <Button key="cancel" onClick={cancelDownload} disabled={downloadProgressProperties.current.cancelling}>
                               {intl.formatMessage({id:'survey-main-general-cancel-button'})}
                           </Button>
                       ]}>
                    <Flex gap="small" wrap={true}>
                        <Progress type="circle" percent={downloadProgressProperties.current.progress} />
                    </Flex>
                    <Paragraph hidden={downloadProgressProperties.current.cancelling}>
                        {intl.formatMessage({id:'document-overview-downloading-wait-msg'})}
                    </Paragraph>
                    <Paragraph hidden={!downloadProgressProperties.current.cancelling}>
                        {intl.formatMessage({id:'document-overview-cancelling-download'})}
                    </Paragraph>
                </Modal>

                <Spin spinning={loading} size={'large'}>

                    <div style={{ ...sizes, minHeight: '200px' }} className="pl15 ag-theme-balham">
                        <Col md={24} sm={12} xs={24}>
                            <Tabs defaultActiveKey={'1'}>
                                {props.distinctEntitlements?.includes('overview@documents') && (
                                    <TabPane
                                        tab={getI18n('Document-title-overview', 'Documents Overview', intl)}
                                        key="1"
                                        id="document-List"
                                    >
                                        <Typography.Title level={5} className={'mb10'}>
                                            {getI18n('Document-title-overview-message', '', intl)}
                                        </Typography.Title>
                                        <Card size={'small'} className={'ml-100'}>
                                            <MyListDocumentsSearchForm
                                                onSearch={onSearch}
                                                currentUser={currentUser}
                                                intl={props.intl}
                                                onDisplay={undefined}
                                                distinctEntitlements={props.distinctEntitlements}
                                                isFsm={false}
                                                gridRef={gridRef}                                                
                                            />
                                            {failureDocuments && (

                                                <Row gutter={24}>
                                                    <Col span={24} className="mb5 mt5">
                                                        {failureDocuments.map((x, i) => (
                                                            <Alert
                                                                key={i}
                                                                message={`${x.errorMessage}`}
                                                                type="error"
                                                                showIcon={true}
                                                                closable={true}
                                                            />
                                                        ))}
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Tabs defaultActiveKey={'1'} className="users-tabs">
                                                        <TabPane
                                                            tab={getI18n(
                                                                'Document-title-overview-documents',
                                                                'Documents',
                                                                intl,
                                                            )}
                                                            key="1"
                                                        >

                                                            <div style={{ paddingBottom: '20px', display: 'block' }}>
                                                                <div>
                                                                    <label>{getI18n('welcome-No_documents', '  Number Of Documents  ', intl)} </label>
                                                                    <Space>
                                                                        <Badge
                                                                            className="site-badge-count-109"
                                                                            count={docCount}
                                                                            style={{ backgroundColor: '#52c41a' }}
                                                                            title={'Number of Documents'}
                                                                            overflowCount={999999}
                                                                        />
                                                                    </Space>
                                                                </div>

                                                                <Row gutter={24} style={{ paddingTop: '5px' }}>
                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Dropdown.Button
                                                                            data-testid={'view-result-customization'}
                                                                            overlay={menu} trigger={['click']}
                                                                            icon={downIcon()} size={'middle'}>
                                                                            <EyeOutlined />
                                                                            {intl.formatMessage({
                                                                                id: 'info-portal-view',
                                                                                defaultMessage: 'View',
                                                                            })}
                                                                        </Dropdown.Button>
                                                                    </Col>

                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Tooltip
                                                                            title={intl.formatMessage({ id: 'reset-filter' })}>
                                                                            <Button
                                                                                data-testid={'view-result-customization-reset'}
                                                                                onClick={onReset} size={'middle'}
                                                                                icon={<ReloadOutlined
                                                                                    className={moduleStyles.simpleFlipRefresh} />}>
                                                                                {intl.formatMessage({ id: 'info-portal-reset' })}
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Tooltip
                                                                            title={intl.formatMessage({ id: 'welcome-download-button' })}>
                                                                            <Button data-testid={'result-download'}
                                                                                    onClick={downloadDocuments}
                                                                                    icon={<DownloadOutlined />}
                                                                                    size={'middle'}>
                                                                                {intl.formatMessage({ id: 'welcome-download-button' })}
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Tooltip
                                                                            title={intl.formatMessage({ id: 'export-to-excel' })}>
                                                                            <Button data-testid={'result-export'}
                                                                                    onClick={onExport}
                                                                                    icon={<DownloadOutlined />}
                                                                                    size={'middle'}>
                                                                                {intl.formatMessage({ id: 'export' })}
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div style={{ flex: 'auto', height: '500px', width: '110%' }}>
                                                                <AgGridReact
                                                                    ref={gridRef}
                                                                    defaultColDef={{
                                                                        enableValue: true,
                                                                        sortable: true,
                                                                        resizable: true,
                                                                    }}
                                                                    api={docGridApi}
                                                                    columnDefs={gridView.current.getView()}
                                                                    rowData={docData}
                                                                    rowClassRules={{
                                                                        'bold-row': (params) => {
                                                                            return !params.data.downloadedBool;
                                                                        },
                                                                    }}
                                                                    getRowStyle={params => {
                                                                        if (!params.data.downloadedBool) {
                                                                            return { backgroundColor: '#ECF3FF' };
                                                                        }
                                                                        return {};
                                                                    }}
                                                                    sideBar={false}
                                                                    rowClass="agGridRows"
                                                                    localeText={getAgGridLocalization(intl)}
                                                                    suppressMenuHide={true}
                                                                    debug={true}
                                                               /*     onGridSizeChanged={() =>
                                                                        docGridApi.sizeColumnsToFit()
                                                                    }*/
                                                                    onGridReady={onDocGridReady}
                                                                    rowSelection={'multiple'}
                                                                    pagination={true}
                                                                    paginationPageSize={15}
                                                                    excelStyles={excelStyles}
                                                                    suppressRowClickSelection={true}
                                                                    enableBrowserTooltips={true}
                                                                    data-testid="documents-test-id"
                                                                />
                                                            </div>
                                                        </TabPane>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}
                                {props.distinctEntitlements?.includes('fsm_overview@documents') && (
                                    <TabPane
                                        tab={getI18n('Fsm-title-overview', 'FSM Overview', intl)}
                                        key="2"
                                        id="fsm-document-List"
                                    >
                                        <FsmDocumentsOverview
                                            intl={intl}
                                            currentUser={currentUser}
                                            distinctEntitlements={props.distinctEntitlements}
                                        />
                                    </TabPane>
                                )}
                            </Tabs>
                        </Col>
                    </div>
                </Spin>
            </PageContainer>
        </ConfigProvider>
    );
};
export default DocumentsOverview;

function updateDownLoadStatus(arg0: { variables: { documentDownloadInput: any } }) {
    throw new Error('Function not implemented.');
}
