import React, {useState} from "react";
import { PortalFormItemSelectEcap } from "./FormUtils";
import { useQuery } from "@apollo/client";
import { listProcessCodes } from "../pages/documents/__generated__/listProcessCodes";
import { SELECT_PROCESSCODE_QUERY } from "../pages/documents/queries";
import { useIntl, WrappedComponentProps } from "react-intl";
import { getI18n } from "../utils/Utils";
import { useLocation } from "react-router-dom";
import { useAppSelector  } from "../main/hooks"
import { getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners } from "../..//src/main/__generated__/getUserEntitlementResultByUserId";
 

type IAppComponentProps = { currentUser?: any; distinctEntitlements?: string[],
 entitlementPartner?:getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners[] , select?: string,
 children?: any } & WrappedComponentProps;

export const ProcessCode: React.FunctionComponent<IAppComponentProps> = (props) => {
  const { data } = useQuery<listProcessCodes>(SELECT_PROCESSCODE_QUERY, {
    fetchPolicy: "network-only",
    variables: { select: props.select, username: props?.currentUser?.username}
   // variables: { select: "PROCESSCODE", username: props.currentUser}
  });
  const intl = useIntl();
  const docOverviewFiltersState = useAppSelector((state) => state.documentOverview);
  const location = useLocation();
  const isFromCaptureDocument = location.state && location.state.isFromCaptureDocument;

  const [processCodeSelectedValue, setProcessCodeSelectedValue] = useState(isFromCaptureDocument
     ? docOverviewFiltersState.documentOverviewFilters.processCodeId: null);
  return (
    <>
      <PortalFormItemSelectEcap type={"single"}
                                label={getI18n("welcome-process-code", "Process Code", intl)}
                                name={"processCodeId"}
                                placeHolder={getI18n("welcome-process-code", "Process Code", intl)}
                                listProcessCodes={data}
                                defaultValue={isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.processCodeId :null} 
                                value={processCodeSelectedValue}
                                onChange={(value) => setProcessCodeSelectedValue(value)}
      />
    </>
  );
};
