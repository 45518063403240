import React, { useEffect, useState } from 'react';
import { IAppComponentProps } from 'src/components';
import { Spin, Tabs, ConfigProvider, Select, Button, message} from 'antd'
import { useIntl } from 'react-intl';
import enUS from 'antd/lib/locale/en_US';
import deDe from "antd/lib/locale/de_DE";
import styles from '../../styles/scss/portal.scss';
import { saveAs } from 'save-as';
import { PageContainer } from '@ant-design/pro-layout';
import { getCurrentLocale } from '../../utils';
import { getI18n } from "../../utils/Utils";
import { DownloadOutlined } from '@ant-design/icons';
import { getUserGuides_getUserGuidesDocList } from './__generated__/getUserGuides';
import { downloadUserGuideByBlobId } from "../help-faq/HelpUserGuideService"
import { getUserGuidesDocs } from "../../pages/help-faq/HelpUserGuideService";
import {Buffer} from "buffer";

const HelpFaqUserGuides: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const [loading, isLoading] = useState(false);
    const [userGuidesDocList, setUserGuidesDocList] = useState<getUserGuides_getUserGuidesDocList[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    useEffect(() => {
        setSelectedLanguage("EN");
        Promise.resolve(getUserGuidesDocs())
            .then((data) => {
                setUserGuidesDocList(data.getUserGuidesDocList);
            })
            .catch((err) => {
                message.error(intl.formatMessage({
                    id: "user-guides-documentLoadFailure",
                    defaultMessage: "Unable to load User Guides document list"
                }))
            });
    }, []);

    const handleChange = (value: string) => {
        setSelectedLanguage(value)
    }
    const handleDownload = (blobIdEN, blobIdDE) => {

         isLoading(true);

        const blobId = selectedLanguage === "EN" ? blobIdEN : blobIdDE

        Promise.resolve(downloadUserGuideByBlobId(blobId))
            .then((data) => {
                const mimeType = data.downloadUserGuideDocument.value.mimeType;
                const decodedData = Buffer.from(data.downloadUserGuideDocument.value.encodedFileData, 'base64');
                const blob = new Blob([decodedData], {type: mimeType});
                saveAs(blob,data.downloadUserGuideDocument.value.fileName);
                message.success(intl.formatMessage({
                    id: "user-guide-downloadSuccess",
                    defaultMessage: "Document downloaded Successfully"
                }))
            })
            .catch((err) => {
                 isLoading(false);

                message.error(intl.formatMessage({
                    id: "user-guide-downloadFailure",
                    defaultMessage: "Document Download failed"
                }))
                console.error(err);
            })
            .finally(() => {
                 isLoading(false);
                 setSelectedLanguage("EN");
            });

    };

    const localeLanguage = getCurrentLocale();
    const userGuideGroups = userGuidesDocList.reduce((groups, guide) => {
        if (!groups[guide.userGuideGroupId]) {
            localeLanguage === "en" ? groups[guide.userGuideGroupId] = guide.userGuideGroupEN : groups[guide.userGuideGroupId] = guide.userGuideGroupDE;
        }
        return groups;
    }, {});

    const items = Object.entries(userGuideGroups).map(([groupId, groupName]) => ({
        key: groupId,
        label: groupName,
        children: userGuidesDocList.filter((el) => el.userGuideGroupId === parseInt(groupId)).map((el, i) => (
            <div key={i}>
                {
                    <div style={{margin:"20px"}} >           

                            <div style={{display: 'inline-block', width:'350px'}}>
                                <h5 style={{color:'rgb(68,68,68)', padding:'0px',margin:'10px 0px'}}>{`${el.userGuideName}`}</h5>
                            </div>
                            <div style={{display: 'inline-block',width:'100px'}}>
                                <Select 
                                defaultValue={'EN'}
                                        options={el.blobIdEN === el.blobIdDE ? [{label: "EN", value: "EN"}] : [{label: "EN", value: "EN"}, {label: "DE", value: "DE"}]}
                                        onChange={(value) => handleChange(value)}
                                />
                            </div>

                            <div style={{display: 'inline-block',width:'100px'}}>
                                <Button icon={<DownloadOutlined/>} size={"middle"}
                                        onClick={() => handleDownload(el.blobIdEN, el.blobIdDE)}
                                >
                                    {intl.formatMessage({id: "info-portal-download"}).toUpperCase()}
                                </Button>
                            </div>                    
                    </div>
                }
            </div>
        ))
    }));


    return (
        <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>

            <Spin size={"large"} spinning={loading}>
                <div style={{fontFamily: "BMW Group Condensed"}}>
                    <PageContainer
                        title={false}
                        content={''}
                        extraContent={''}
                        className={styles.users}
                        style={{ background: 'white' }}
                    >
                        {  <h3 style={{}}> {getI18n('help-document-download','Help' , intl)}</h3> }
                        {  <h4> {getI18n('help-document-download-instructions','Select the language of the document and click  on \"DOWNLOAD\":' , intl)}</h4>}

                        {/* { index === 0 ? <h4> {getI18n('help-document-download-instructions','Select the language of the document and click  on \"DOWNLOAD\":' , intl)}</h4> : null} */}
                        <Tabs defaultActiveKey="1" items={items}/>

                    </PageContainer>
                </div>
            </Spin>
        </ConfigProvider>

    );

};

export default HelpFaqUserGuides;


