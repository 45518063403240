import client from "../../../config/GraphQLApolloClient";
import { QUERY_DUPLICATE_DOCUMENT_NUMBERS } from "./queries";

export const getDuplicateDocumentNumberFlag = (documentNumberInput: string, partnerNumberInput: string) => {
        return new Promise((resolve, reject) => {
            client.query({
                query: QUERY_DUPLICATE_DOCUMENT_NUMBERS,
                variables: {authentication: window.localStorage.getItem("id_token"), documentNumberInput, partnerNumberInput},
                fetchPolicy: "network-only"
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((err) => {
                console.error(err);
                reject(err);
              });
          });
    } 