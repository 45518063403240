import React, {useEffect, useState} from "react";
import {Alert, Card, Col, ConfigProvider, Form, Row, Spin, Tabs} from "antd";
import {PageContainer} from "@ant-design/pro-layout";
import {FailureDownloadDocsResp, IAppComponentProps} from "../../components";
import styles from "../../styles/scss/portal.scss";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {CustomRender} from "../../components/CustomRender";
import {GridApi} from "ag-grid-community";
import client from "../../config/GraphQLApolloClient";
import enUS from "antd/lib/locale/en_US";
import {DOWNLOAD_MULTIPLE_DOCUMENT} from "./queries";
import {showErrorNotification} from "../Notification";
import {UPDATE_MULTIPLE_DOWNLOAD_STATUS} from "./mutations";
import MyDocumentSearchForm from "./myDocumentSearchForm";
import {getDocumentsData} from "./DocumentService";
import {base64toZipFile, MYDOCS_TABLE_HEADERS} from "./DocumentUtils";
import deDe from "antd/lib/locale/de_DE";
import {FormattedMessage, useIntl} from "react-intl";
import {getAgGridLocalization} from "../info-portal/components/grid/GridUtil";
import {getI18n} from '../../utils/Utils';
import dayjs from 'dayjs';

export const renderAsDate = (record) => <CustomRender record={record} renderAs="date"/>;
export const renderAsButton = (record) => <CustomRender record={record} renderAs="button"/>;

const MyDocuments: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const [loading, isLoading] = useState(false);
    const currentUser = props.currentUser;
    const [form] = Form.useForm();
    const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
    const [docData, setDocData] = useState(undefined);
    const [docCount, setDocCount] = useState(0);
    const [formDataValues, setFormDataValues] = useState(undefined);
    const [failureDocuments, setFailureDocuments] = useState<FailureDownloadDocsResp[]>(undefined);

    const {TabPane} = Tabs;
    const sizes: React.CSSProperties = {
        height: "100%",
        width: "98%"
    };
    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16}
    };

    useEffect(() => {
        isLoading(false);
        setDocCount(0);
        setDocData(null);
        // const input = {
        //   fromDate: dayjs().startOf("d"),
        //   toDate: dayjs().endOf("d")
        // };
        // Promise.resolve(getDocumentsData(currentUser.username, input))
        //   .then((data) => {
        //     setDocCount(data.getPartnerDocuments.length);
        //     setDocData(data.getPartnerDocuments);
        //     isLoading(false);
        //   })
        //   .catch((err) => {
        //     isLoading(false);
        //     console.error(err);
        //   });


    }, [props.currentUser]);

    const onDocGridReady = (params) => {
        setDocGridApi(params.api);

    };

    const onSearch = (values) => {
        isLoading(true);
        setFormDataValues(values);

        const documentInput = {
                fromDate: values.fromDate ? dayjs(values.fromDate.startOf("d")) : undefined,
                toDate: values.dateTo ? dayjs(values.dateTo.endOf("d")) : undefined,
                companyCode: values.companyCode,
                reference: values.reference ? values.reference.trim() : undefined,
                partnerno: values.partnerNo ? values.partnerNo.trim() : undefined,
                downloaded: values.downloaded,
                orderArea: values.orderArea
            }
        ;
        console.log('The values', values);
        console.log('The document input', documentInput);
        localStorage.setItem("documentInput", JSON.stringify(documentInput));
        Promise.resolve(getDocumentsData(currentUser.username, documentInput))
            .then((data) => {
                // eslint-disable-next-line no-console
                setDocCount(data.getPartnerDocuments.length);
                setDocData(data.getPartnerDocuments);
                isLoading(false);
            })
            .catch((err) => {
                isLoading(false);
                console.error(err);
            });

    };

    const onExport = () => {
        const columnIDS = MYDOCS_TABLE_HEADERS(intl, currentUser).map(item => item.colId);
        docGridApi.exportDataAsExcel({
            onlySelectedAllPages: true,
            columnKeys: columnIDS.slice(0, columnIDS.length - 1)
        });
    };

    const downloadDocuments = (values) => {
        isLoading(true);
        setFormDataValues(values);
        const documentDownloadInputList = [];
        const selectedRows = docGridApi.getSelectedRows();
        console.log(selectedRows);
        for (const document of selectedRows) {
            documentDownloadInputList.push({
                blobId: document.documentId,
                documentName: document.azDownloadFileName,
                documentPath: document.azItemPathName,
                documentLink: document.downloadLink
            });
            console.log(documentDownloadInputList);
        }
        client
            .query({
                query: DOWNLOAD_MULTIPLE_DOCUMENT,
                variables: {
                    documentDownloadInputs: documentDownloadInputList
                },
                fetchPolicy: "network-only"
            })
            .then((response) => {
                base64toZipFile(response.data.downloadMultipleDocument.encodedZipFileData, new Date().toISOString().substring(0, 10).concat("-download.zip"));
                setFailureDocuments(response.data.downloadMultipleDocument.failureDocuments);
                isLoading(false);
            })
            .then(() => {
                client.mutate({
                    mutation: UPDATE_MULTIPLE_DOWNLOAD_STATUS,
                    variables: {documentDownloadInputs: documentDownloadInputList}
                }).then((_) => {
                    onSearch(values);
                }).catch((_) => {
                    // showErrorNotification(`${e}`);
                });
            })
            .catch((e) => {
                console.error(e);
                isLoading(false);
                showErrorNotification(`${e}`);
            });
    };


    return (
        <ConfigProvider locale={localStorage.getItem("locale") === "us" ? enUS : deDe}>
            <PageContainer
                title={false}
                content={""}
                extraContent={""}
                className={styles.users}
                style={{background: "white"}}
            >
                <Spin spinning={loading} size={"large"}>

                    <div style={{...sizes, minHeight: "200px"}} className="pl15 ag-theme-balham">
                        <Col md={24} sm={12} xs={24}>
                                <Card title={getI18n("freight_panel_header_title", "Document view", intl)}
                                         key="1">
                                    <MyDocumentSearchForm count={docCount} onExport={onExport}
                                                          onDownloadDocuments={downloadDocuments}
                                                          onSearch={onSearch} currentUser={currentUser}
                                                          intl={props.intl}
                                                          distinctEntitlements={props.distinctEntitlements}/>

                                    {failureDocuments &&
                                        <Row gutter={24}>
                                            <Col span={24} className="mb5 mt5">
                                                {failureDocuments.map((x, i) =>
                                                    <Alert key={i} message={`${x.errorMessage}`} type="error"
                                                           showIcon={true} closable={true}/>)}
                                            </Col>
                                        </Row>}

                                <Card size={"small"} title={"Documents"}>
                                    <div style={{flex: "auto", height: "700px"}}>
                                        <AgGridReact
                                            defaultColDef={{
                                                enableValue: true,
                                                sortable: true,
                                                resizable: true,
                                            }}
                                            api={docGridApi}
                                            columnDefs={MYDOCS_TABLE_HEADERS(intl, currentUser)}
                                            rowData={docData}
                                            sideBar={false}
                                            rowClass="agGridRows"
                                            suppressMenuHide={true}
                                            debug={true}
                                            onGridSizeChanged={() => docGridApi.sizeColumnsToFit()}
                                            onGridReady={onDocGridReady}
                                            rowSelection={"multiple"}
                                            pagination={true}
                                            localeText={getAgGridLocalization(intl)}
                                            //overlayNoRowsTemplate={"<span><FormattedMessage id=\"freight_panel_grid_noRowMessage\" defaultMessage=\"No Rows to Show\"/></span>"}
                                            paginationPageSize={20}
                                            suppressRowClickSelection={true}
                                            enableBrowserTooltips={true}
                                            data-testid="documents-test-id"/>
                                    </div>
                                </Card>
                                </Card>
                        </Col>
                    </div>
                </Spin>
            </PageContainer>
        </ConfigProvider>
    )
        ;
};
export default MyDocuments;
