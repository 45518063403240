import client from "../../../config/GraphQLApolloClient";
import {CREATE_CONFIRMATION_OF_ARRIVAL_DOCUMENT} from "../graphql/queries";
import {CORAPI_GeneratedPDFResponse, CreateConfirmationOfArrivalPDFVariables} from "../graphql/__generated__/CORAPI";
import {CR1PDFRequest} from "../../../../__generated__/globalTypes";

export async function generateConfirmationOfArrivalPDF(rows:CR1PDFRequest[]) {
    for (const row of rows) {
        const modifiedRow = {...row, __typename:undefined};
        for (let i = 0; i < modifiedRow.positions?.length || 0; i++) {
            // @ts-ignore
            modifiedRow.positions[i] = {... modifiedRow.positions[i], __typename:undefined};
        }
        const response = await client.query<CORAPI_GeneratedPDFResponse,CreateConfirmationOfArrivalPDFVariables>(
            {
                query: CREATE_CONFIRMATION_OF_ARRIVAL_DOCUMENT,
                variables: {input: modifiedRow},
                fetchPolicy:'network-only'
            }).then(response => response.data.createConfirmationOfArrivalPDF);

        row.blobId = response.blobId;
        row.documentCreationDate = response.creationTime;
        row.documentCreated = true;
    }

    console.log('Generating PDF for rows:', rows);

    return Promise.resolve(true);
}