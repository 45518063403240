import React from "react";
import { Button, Col, Modal, Row } from "antd";
import { getI18n } from "../../../utils/Utils";
import { useIntl } from "react-intl";

type DuplicateInvoiceWarningModalProps = {
  isModalVisible: any;
  handleNoInModal: any;
  handleYesInModal: any;
  handleCancelInModal: any;
}
const DuplicateDocumentNoWarningModal: React.FunctionComponent<DuplicateInvoiceWarningModalProps> = (props) => {
  const intl = useIntl();
  const handleNoInModal = () => {
    props.handleNoInModal();
  }

  const handleYesInModal = () => {
    props.handleYesInModal();
  }

  return (
    <>
      <Modal
        title={getI18n("e-cap-capture-document-duplicate-document-number-warning-heading", "Warning", intl)}
        open={props.isModalVisible}
        width={'900px'}
        mask={true}
        onCancel={() => props.handleCancelInModal()}
        footer={[
          <Button key="back"
            onClick={handleNoInModal}
          >
            {getI18n("general-edp-no", "No", intl)}
          </Button>,
          <Button key="submit" type="primary"
            onClick={handleYesInModal}
          >
            {getI18n("general-edp-yes", "Yes", intl)}
          </Button>
        ]}
      >
        <Row gutter={24}>
          <Col >
            <div className="pl15">
              <h5>{`${getI18n("e-cap-capture-document-duplicate-document-number-warning-message", "The Document Number you entered was already submitted this calendar year. Would you still like to use the same Document Number? ", intl)} `}</h5>
            </div>
          </Col>
        </Row>
      </Modal>

    </>
  );
};

export default DuplicateDocumentNoWarningModal;